import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import getConfig from 'next/config';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Europe/Paris');

const { publicRuntimeConfig } = getConfig();

export const { ACTIROUTE_URL, API_URL, MAINTENANCE_MODE, DEBUG } =
  publicRuntimeConfig;

export const MEETING_API_URL =
  publicRuntimeConfig.MEETING_API_URL || `${API_URL}/meetings`;
