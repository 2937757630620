var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/api/tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"KDApRX4XgQzSxW9rvXxmS"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const SENTRY_DSN =
  publicRuntimeConfig.SENTRY_DSN || publicRuntimeConfig.NEXT_PUBLIC_SENTRY_DSN;

if (publicRuntimeConfig.DEPLOY_TYPE !== 'local') {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://1df9483bb0344a2bb9307bf91b65da92@o525849.ingest.sentry.io/4505232942563328',
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    environment: publicRuntimeConfig.DEPLOY_TYPE || 'not_set',
    tunnel: '/api/tunnel',
  });
}
